import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { useMediaQuery, useTheme } from "@mui/material";
import { Select, MenuItem } from "@mui/material";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import dayjs from "dayjs";
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

let rowImmutableStore;

const InvoiceDetails = () => {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  const gridRef = useRef();

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "InvoiceID",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    { field: "Date", minWidth: 180 },
    { field: "Address", minWidth: 150 },
    { field: "ReceiverAddress", minWidth: 160 },
    { field: "Page" },
    { field: "Exchange" },
    {
      field: "ItemPrice",
      headerName: "ItemPrice",
      // editable: true,
      minWidth: 170,
    },
    {
      field: "Shipper",
      headerName: "Shipper ",
      // editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [],
      },
    },
    {
      field: "ShipmentStatus",
      headerName: "ShipmentStatus",
      minWidth: 170,
      // editable: true,
      cellClassRules: {
        "status-pickup": (params) => params.value === "pickup",
        "status-pending": (params) =>
          params.value === "pending" || params.value === "pending2",
        "status-delivered": (params) => params.value === "delivered",
        "status-canceled": (params) => params.value === "canceled",
        "status-delayed": (params) => params.value === "delayed",
        "status-cancel-paid": (params) => params.value === "cancel_paid",
        "status-cancel-paid-delivery": (params) =>
          params.value === "cancel_paid_delivery",
      },
    },
    {
      field: "ItemPriceLBP",
      headerName: "ItemPriceLBP",
      // editable: true,
      minWidth: 170,
    },
    {
      field: "LBPSubPrice",
      headerName: "LBPSubPrice",
      // editable: true,
      minWidth: 170,
    },
    { field: "DeliveryCost" },
    {
      field: "DeliveryProfit",
      headerName: "Delivery Profit ",
      // editable: true,
      minWidth: 170,
    },
    { field: "LabytakProfit" },
    { field: "Notes" },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    cellDataType: false,
    flex: 1,
    minWidth: 150,
    filter: true,
    floatingFilter: true,
  }));

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  const lineBox = {
    display: "flex",
    // flexDirection: isBigScreen ? "row" : "column",
    gap: "20px",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px 0",
  };

  const getRowId = useMemo(() => {
    return (params) => params.data.id;
  }, []);

  const [invoiceID, setInvoiceID] = useState("");
  const [invoiceDetails, setinvoiceDetails] = useState([]);

  const findInvoice = () => {
    document.getElementById("loaderDA").style.visibility = "visible";
    if (invoiceID === "") {
      alert("Please Enter an InvoiceID");
      document.getElementById("loaderDA").style.visibility = "hidden";
      return;
    } else {
      let data = new FormData();
      data.append("invoiceID", invoiceID);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/getInvoiceById",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then(async (response) => {
          // console.log(response.data);
          if (response.data.message === "No Data Found") {
            alert("No Data Found");
            setinvoiceDetails([]);
            document.getElementById("loaderDA").style.visibility = "hidden";
          } else {
            const transformedData = response.data.resultInvoice.map((row) => ({
              id: row.id,
              InvoiceID: row.invoiceID,
              Date: dayjs(row.statusDate).format("YYYY-MM-DD HH:mm:ss"),
              Address: row.address,
              ReceiverAddress: row.receiverAddress,
              FullName: row.firstname + " " + row.lastname,
              Page: row.companyName,
              Exchange: row.exchange,
              ItemPrice: row.itemPrice,
              ItemPriceLBP: row.itemPriceLBP,
              LBPSubPrice: row.lbpSubPrice,
              DeliveryCost: row.shippingCost,
              DeliveryProfit: row.shipperProfit,
              LabytakProfit: row.labaytakProfit,
              Notes: row.notes,
              Shipper: `${row.shipper_firstname} ${row.shipper_lastname}`,
              ShipmentStatus: row.shipmentStatus,
            }));

            rowImmutableStore = transformedData;
            setinvoiceDetails(transformedData);
            document.getElementById("loaderDA").style.visibility = "hidden";
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            alert("Invoice not found");
            setinvoiceDetails([]);
            document.getElementById("loaderDA").style.visibility = "hidden";
          } else {
            console.error("An error occurred:", error);
          }
        });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
          width: isBigScreen ? "600px" : "100%",
        }}
      >
        <FormControl required sx={{ m: 1, width: "75%" }} size="medium">
          <TextField
            id="invoiceID"
            label="invoiceID"
            type="text"
            value={invoiceID}
            onChange={(e) => {
              setInvoiceID(e.target.value);
            }}
          />
        </FormControl>

        <CircularProgress
          id="loaderDA"
          color="success"
          sx={{ visibility: "hidden" }}
        />

        <Button variant="contained" color="success" onClick={findInvoice}>
          Find Invoice
        </Button>
      </Box>

      <div className="ag-theme-alpine" style={{ width: "100%", height: 500 }}>
        <AgGridReact
          ref={gridRef}
          rowData={invoiceDetails}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          getRowId={getRowId}
          // onSelectionChanged={onSelectionChanged}
          // onCellClicked={cellClickedListener}
          // onCellEditRequest={onCellEditRequest}
          suppressRowClickSelection={true}
          readOnlyEdit={true}
        />
      </div>
    </>
  );
};

export default InvoiceDetails;
